@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}



:root {
  --blue: #5e72e4;
  --indigo: #5603ad;
  --purple: #8965e0;
  --pink: #f3a4b5;
  --red: #f5365c;
  --orange: #fb6340;
  --yellow: #ffd600;
  --green: #2dce89;
  --teal: #11cdef;
  --cyan: #2bffc6;
  --gray: #6c757d;
  --gray-dark: #32325d;
  --light: #ced4da;
  --lighter: #e9ecef;
  --primary: #e14eca;
  --secondary: #f4f5f7;
  --success: #00f2c3;
  --info: #1d8cf8;
  --warning: #ff8d72;
  --danger: #fd5d93;
  --light: #adb5bd;
  --dark: #212529;
  --default: #344675;
  --white: #fff;
  --neutral: #fff;
  --darker: #000;
}

body {
  background: #f8f9fb !important;
  font-family: 'Poppins', sans-serif;
}

.logo-img {
  width: 100%;
  height: 60px;
}

li {
  list-style: none;
}

/* Login */
.google-btn {
  background: transparent;
  transition: .3s all ease;
}

.google-btn:hover {
  border: 1px solid #1991eb !important;
  transition: .3s all ease;
}

.box-login-shadow {
  box-shadow: 3px 3px 10px 3px #dddddd;
}

.bg-transparent {
  background: transparent !important;
}

/* .DashBoxesSection {
  margin-top: 50px;
} */
.circle {
  clip-path: circle(0 at right 200px);
}

.bg-pink-primary {
  background: #ff0083 !important;
}

.dashBoxes:hover .circle {
  clip-path: circle(1000px at right 200px);
  transition: 2s all ease-in-out;
}

.dashBoxes:hover .box-white-text {
  color: #fff;
  transition: 1s all ease-in-out;
}

.dashBoxes {
  transition: 2s all ease-in-out;
  box-shadow: 0px 0px 12px 6px rgba(158, 158, 158, 0.5098039216);
  padding: 30px 10px;
  border-radius: 8px;
  background: #fff;
}

.dashBoxes:hover.dashBoxes h5 {
  animation-name: slideText;
  animation-duration: 2s;
  transition: 1s;
}

.dashBoxes:hover.dashBoxes h4 {
  animation-name: slideText;
  animation-duration: 2s;
  transition: 1s;
}

@keyframes slideText {
  0% {
    transform: translateX(20px);
  }

  100% {
    transform: translateX(0px);
  }
}


.dashBoxes h4 svg {
  margin-right: 5px;
  font-size: 42px;
  border: 1px dashed;
  border-radius: 20px;
  padding: 3px 4px;
}

@media only screen and (max-width:768px) {
  .dashBoxes {
    margin: 20px 0px;
  }
}

.dashBoxes h4 span {
  font-size: 22px;
}

.dashBoxes h5 {
  margin-top: 10px;
  font-size: 25px;
  font-weight: 400;
  letter-spacing: 1px;
  border-bottom: 1px dashed;
  display: inline-block;
  padding-bottom: 5px;
}

/*# sourceMappingURL=dashboard.css.map */


.active {
  color: #ff0075;
}

.border-dotted {
  border-bottom: 1px dotted black !important;
}

.dashboard-box {
  height: 150px !important;
  max-width: 240px !important;
  font-size: 18px;
}

.login-container {
  height: auto;
  max-width: 400px;
}

.tdn {
  text-decoration: none !important;
}

.tab-active {
  position: relative;
}

.dropdown-menu.show {
  width: 100% !important;
}

.tab-active::after {
  content: "";
  position: absolute;
  bottom: -5px;
  background: #1991eb;
  width: 100%;
  height: 1px;
  left: 0;
}

.form-control:focus {
  box-shadow: none !important;
  border: 1px solid var(--blue) !important;
}

.cursor {
  cursor: pointer;
}

.login-btn {
  background: #949fb1;
  width: 100%;
  outline: none;
  border: none;
  transition: .2s all ease-in-out;
}

.login-btn:hover {
  box-shadow: 3px 3px 10px 3px #dddddd;
}

.form {
  /* width: fit-content; */
  /* margin: auto; */
  padding: 0px;
  background: #27293d;
}

.sidebar ul li {
  list-style: none;
}

/* Chart css */
.visitorsChartHead {
  font-size: 20px;
  color: #a2a5bd;
}

.visitorsChart {
  box-shadow: 2px 2px 10px rgba(158, 158, 158, 0.5098039216);
  padding: 16px 10px;
  border-radius: 10px;
}

.visitorsChart .innerVisitChartBox {
  display: flex;
  justify-content: space-between;
  align-items: self-end;
  border-radius: 10px;
}

.visitorsChart .chart {
  width: 50% !important;
}

.visitorsChart .visitCountPerWeek {
  margin-top: 10px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.visitorsChart .visitCountPerWeek h6 {
  font-size: 33px;
}

.visitorsChart .visitCountPerWeek span {
  font-size: 15px;
  color: #a2a5bd;
}

.earningChartBox {
  box-shadow: 2px 2px 10px rgba(158, 158, 158, 0.5098039216);
  border-radius: 10px;
  padding: 10px 30px;
}

.earningChartBox .earningPerDayBox h3 {
  color: black;
  margin: 0;
  font-size: 22px;
}

.earningChartBox .earningPerDayBox span {
  font-size: 13px;
  color: #a2a5bd;
}

.SalesProgress {
  box-shadow: 2px 2px 10px rgba(158, 158, 158, 0.5098039216);
  border-radius: 10px;
  padding: 10px 30px;
  margin-top: 50px;
}

.SalesProgress .SalesProgressHead {
  margin: 10px 0;
}

.SalesProgress .SalesProgressHead span {
  color: #a2a5bd;
}

.SalesProgress .SalesProgressHead h5 {
  margin: 5px 0;
}

.OneMonSalesProgress {
  box-shadow: 2px 2px 10px rgba(158, 158, 158, 0.5098039216);
  border-radius: 10px;
  padding: 10px 30px;
  margin-top: 50px;
}

.OneMonSalesProgress .OneMonSalesProgressHead {
  margin: 10px 0;
}

.OneMonSalesProgress .OneMonSalesProgressHead span {
  color: #a2a5bd;
}

.OneMonSalesProgress .OneMonSalesProgressHead h5 {
  margin: 5px 0;
}

.OneMonSalesProgress .OneMonSalesProgressHead .OneMonInnerBox {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 20px;
}

.OneMonSalesProgress .OneMonSalesProgressHead .OneMonInnerBox span {
  font-size: 13px;
}
.edit-button{
  border: none;
  background-color: transparent;
  outline: none;
}
.set-alert-1{
  padding: 0px 10px !important;
  background-color: transparent !important;
}
.set-link-1{
  text-decoration: none !important;
  color: #d32f2f !important;
  padding: 5px 80px !important;
}
.button-set-1{
  padding: 0 !important;
}
/*# sourceMappingURL=homeFile.css.map */
.set-create-package-div-1{
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}
.set-create-package-div-1 input:nth-child(1){
  width: 50%;
}
.set-pagination-div-1{
  display: flex;
  justify-content: end;
  margin: 10px 0;
}
.css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: rgba(242, 0, 134) !important;
}
button.MuiButtonBase-root.MuiPaginationItem-root.MuiPaginationItem-sizeMedium.MuiPaginationItem-outlined.MuiPaginationItem-rounded.MuiPaginationItem-page.css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root {
  border-color:#f20086 !important;
  color: #000 !important;
  border-radius: 0px !important;
}
button.MuiButtonBase-root.MuiPaginationItem-root.MuiPaginationItem-sizeMedium.MuiPaginationItem-outlined.MuiPaginationItem-rounded.MuiPaginationItem-previousNext.css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root {
  border-color:#f20086 !important;
  color: #000 !important;
  border-radius: 0px !important;
}
.export-csv-btn-1{
  border-color:#f20086 !important;
  color: #f20086 !important;
}
.export-csv-btn-1:hover{
  background-color: rgba(242, 0, 134, 0.04) !important;
}